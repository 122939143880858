import React from "react";
import {QuiNousSommesPage} from 'amg-it-theme'; 

let quiNousSommesPageData ={
  header: {
    background: "societe",
    title: "société",
    path: "ACCUEIL / SOCIÉTÉ / QUI SOMMES-NOUS ?"
  },
  content:{
    category: "qui sommes-nous?",
    title: "Une entreprise de services du numérique (ESN)",
    subtitle: "Créée en 2014, société à taille humaine, AMG-IT se distingue par sa proximité avec ses clients mais aussi ses collaborateurs par une approche inédite de la gestion des ressources humaines."
  },
  boss:{
      name: "Yann GARLANTEZEC",
      title: {
          text: "Fondateur"
      },
      cv: [
        {
            text: "Titulaire d’un DUT à l’IUT d’Orsay, Yann Garlantezec, a démarré sa carrière comme technicien pour devenir ingénieur puis responsable d’agence chez SCC et Helpline (groupe Neurones). "
        },
        {
        text: "Après cette expérience en ESN, il a souhaité créer sa propre société de services en apportant une vision différente de la gestion des ressources humaines."
        }
      ]
  },
  presentations: [
      {
          title: "Le métier premier d’AMG-IT concerne le poste de travail :",
          list_items: [
            {
                text:"Assistance technique"
            },
            {
                text:"Infogérance ou déploiement de parc"
            },
            {
                text:"Administration systèmes et réseaux"
            }
          ]
      },
      {
        title: "La société propose aussi des prestations connexes :",
        list_items: [
          {
              text:"Gestion des incidents, de sécurité réseaux et d’ingénierie"
          },
          {
              text:"Conseil en architecture"
          },
          {
              text:"Conduite du changement"
          },
          {
              text:"Formation pour accompagner les entreprises dans leur transformation numérique."
          }
        ]
    }
  ]
}

const LocalPage = () => (
  <QuiNousSommesPage {...quiNousSommesPageData}/>
);

export default LocalPage;